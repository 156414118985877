<template>
  <div>
    <el-row>
      <el-col :md="{ span: 24, offset: 0 }" :sm="24">
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          :class="checkRTL"
          required
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            v-model="model[fields.question.name]"
            @blur="handleQuestionBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 24, offset: 0 }" :sm="24">
        <el-form-item
          :label="fields.subtitle.label"
          :prop="fields.subtitle.name"
          :class="checkRTL"
          required
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            v-model="model[fields.subtitle.name]"
            @blur="handleSubtitleBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="options-wrap">
          <div class="options-pick">
            <el-form-item required prop="inputs">
              <el-checkbox-group v-model="model[fields.inputs.name]">
                <el-checkbox
                  :key="index"
                  v-for="(option, index) in model[this.fields.options.name]"
                  :label="option"
                  :class="rtlAndPadding"
                  > <span class="card-title"> {{ index + 1 }} </span> </el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </div>
          <div class="options-items">
            <div
              class="options-item"
              :key="index"
              v-for="(_, index) in model[fields.options.name]"
            >
              <el-form-item
                :prop="'options.' + index"
                :rules="{
                  required: true,
                  message: 'Required',
                  trigger: 'blur',
                }"
                :class="checkRTL"
              >
                <el-input
                  placeholder="Option"
                  v-model="model[fields.options.name][index]"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                />
              </el-form-item>
              <span
                @click="remove(index)"
                class="option-delete"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="option-action">
          <el-button
            @click="addOption"
            icon="el-icon-fa-plus"
            type="primary"
          >
            Add Option
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';
export default {
  name: 'multipleOption',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
      rtlAndPadding: 'question/form/rtlAndPadding',
    }),
  },

  methods: {
    addOption() {
      this.model[this.fields.options.name].push('');
    },
    remove(index) {
      const str = this.model[this.fields.options.name][index].trim();
      if (str !== '') {
        const confirm = window.confirm('Are you sure');
        if (!confirm) return;
      }
      this.$delete(this.model[this.fields.options.name], index);
      this.model[this.fields.inputs.name] = [];
    },
    handleQuestionBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
    handleSubtitleBlur(e) {
      this.model[
        this.fields.subtitle.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
  },
};
</script>

<style>
.option-action {
  display: flex;
  justify-content: center;
  border-top: 1px solid #efefef;
  padding-top: 2rem;
  margin-top: 2rem;
}
.options-wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  border-top: 1px solid #efefef;
  margin-top: 1rem;
}

.options-pick {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-items {
  flex: 6;
}

span.option-delete {
  position: absolute;
  right: -2.5rem;
  top: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

span.option-delete:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}

.options-pick label {
  width: 100% !important;
  margin-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  height: 54px;
  align-items: center;
}
.options-pick .el-radio-group {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.options-pick > div > div {
  margin-left: 0 !important;
  height: 100%;
}

.options-pick > div {
  margin-bottom: 0;
  height: 100%;
}

.options-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}
.options-item .el-form-item__content {
  margin-left: 0 !important;
}
.options-item > div {
  margin-bottom: 0;
}
.options-pick .el-form-item__error {
  left: -40px;
  width: 120px;
}
.options-item .el-form-item__content {
  margin-left: 0 !important;
}

.options-pick .el-form-item__content {
  margin-left: 0 !important;
}

.options-wrap .el-form-item__content {
  margin-left: 0 !important;
}
</style>
