<template>
  <div>
    <el-row>
      <el-col
        class="reorder-input"
        :md="{ span: 24, offset: 0 }"
        :sm="24"
      >
        <el-button
          v-show="showAdd"
          class="reorder-add"
          icon="el-icon-fa-plus"
          type="primary"
          @click="addOption"
        >
          <app-i18n code="common.add"></app-i18n>
        </el-button>
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          :class="checkRTL"
          required
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 12 }"
            v-model="model[fields.question.name]"
            id="editor-wrap"
            @blur="handleBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="selected-blanks">
          <div
            class="selected-blank"
            :key="index"
            v-for="(option, index) in options"
          >
            <el-row>
              <el-col :span="12">
                <span
                  @click="removeOption(index)"
                  class="option-delete"
                  ><i class="el-icon-delete"></i>
                  Delete</span
                >
                <div class="select-wrap">
                  <select
                    v-model="options[index].tmp"
                    class="option-select"
                    @change="changeSubOption(index)"
                  >
                    <option
                      :key="itemIndex"
                      v-for="(item,
                      itemIndex) in option.items"
                      :value="itemIndex"
                      :class="rtlAndPadding"
                      > <span class="card-title">{{ item }}</span>
                      <span
                        v-if="itemIndex > 0"
                        class="option-delete"
                        ><i class="el-icon-minue"></i
                      ></span>
                    </option>
                    <option :value="-1"
                      >-- Add Option</option
                    >
                  </select>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="sub-option-action">
                  <input
                    type="text"
                    v-model="options[index].tmpText"
                    class="el-input__inner"
                    placeholder="Add Option"
                    :class="checkRTL"
                  />
                  <div class="sub-option-btns">
                    <el-button
                      class="sub-option-btn"
                      icon="el-icon-fa-floppy-o"
                      type="primary"
                      :disabled="!enableSubSave(index)"
                      round
                      @click="saveSubOption(index)"
                    />
                    <el-button
                      class="sub-option-btn"
                      icon="el-icon-delete"
                      round
                      :disabled="!enableDelete(index)"
                      @click="deleteSubOption(index)"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex'
let selectionStart;
let selectionStop;
let selectedText;

export default {
  name: 'selectedFillInTheBlanks',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAdd: false,
      options: [],
    };
  },
  created() {
    document.addEventListener(
      'selectionchange',
      this.handleSelection,
    );
    this.resetSelection();
    // ilk oluştururken modeldeki optionlari local bir değişkene atarız.
    // burada artık hem öğrenci hem cevap dataları olacak
    const dummyAnswerOptions = JSON.parse(
      JSON.stringify(this.model.optionsAnswer),
    );

    const dummyStudentOptions = JSON.parse(
      JSON.stringify(this.model.options),
    );

    let newOptions = [];

    for (let i = 0; i < dummyAnswerOptions.length; i++) {
      const correct = dummyAnswerOptions[i]; // bu doğru seçenek
      let items = [correct];
      const fakeOptions = dummyStudentOptions[i];
      for (let j = 0; j < fakeOptions.length; j++) {
        const fakeOption = fakeOptions[j];
        if (fakeOption === correct) {
          continue;
        } // karışmış seçenekler, doğru olanı eklenmez. zaten yukarıda eklendi
        items.push(fakeOption);
      }
      const newOption = {
        tmpText: '', // elle girilecek alanların modeli için
        tmp: 0, // seçilen select index için
        items,
      };
      newOptions.push(newOption);
    }

    this.options = newOptions;
  },
  mounted() {
    //console.log(document.getElementById('editor-wrap'));
    document.getElementById(
      'editor-wrap',
    ).onmouseup = this.mainHandle;
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
      rtlAndPadding: 'question/form/rtlAndPadding',
    }),
  },

  methods: {
    handleBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
    removeOption(index) {
      const parts = this.model.question.split('$$');
      let newStr = '';
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        newStr += part;
        if (i === index) {
          newStr += this.options[index].items[0];
        } else if (i < parts.length - 1) {
          newStr += '$$';
        }
      }
      this.$delete(this.options, index);
      this.model.question = newStr;
    },
    resetSelection() {
      selectionStart = 0;
      selectionStop = 0;
      selectedText = '';
      this.showAdd = false;
    },
    getOptionIndex() {
      const before = this.model.question.substr(
        0,
        selectionStart,
      );
      return before.split('$$').length - 1;
    },
    mainHandle() {
      if (this.model.question == null) return; // ilk sorun girerken hata veriyor
      //console.log('main handle');
      selectedText = this.model.question.substring(
        selectionStart,
        selectionStop,
      );
      if (selectedText.trim() === '') {
        this.resetSelection();
        return;
      }
      if (!this.showAdd) {
        this.showAdd = true;
      }
    },
    shuffle(array) { // Re-Orderdaki gibi verimli hale getirilebilir
      let counter = array.length;
      if (counter < 2) return array;

      // While there are elements in the array
      while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
      }

      return array;
    },
    addOption() {
      this.options.splice(this.getOptionIndex(), 0, {
        items: [selectedText],
        tmp: 0,
        tmpText: '',
      });

      this.model.question = this.replace(
        this.model.question,
        selectionStart,
        selectionStop - 1,
      );
      this.resetSelection();
    },
    handleSelection() {
      const activeElement = document.activeElement;
      if (activeElement.id !== 'editor-wrap') {
        this.resetSelection();
        return;
      }
      selectionStart = activeElement.selectionStart;
      selectionStop = activeElement.selectionEnd;
    },

    placeholder(start, end) {
      let out = '';
      for (let i = 0; i < end - start; i++) {
        out += '_';
      }
      return out + ' ';
    },
    replace(origin, start, end) {
      const firstPart = origin.substr(0, start);
      const middlePart = '$$';
      const lastPart = origin.substr(end + 1);
      return firstPart + middlePart + lastPart;
    },
    saveSubOption(index) {
      const option = this.options[index];
      if (option.tmp < 1) {
        option.items.push(option.tmpText);
      } else {
        option.items[option.tmp] = option.tmpText;
      }
      option.tmp = -1;
      option.tmpText = '';
      this.sync();
    },
    deleteSubOption(index) {
      const option = this.options[index];
      if (option.tmp > 0) {
        this.$delete(option.items, option.tmp);
        option.tmpText = '';
        option.tmp = -1;
      }
      this.sync();
    },
    enableSubSave(index) {
      return (
        this.options[index].tmpText &&
        this.options[index].tmpText.trim() !== ''
      );
    },
    enableDelete(index) {
      return this.options[index].tmp > 0;
    },
    changeSubOption(index) {
      const option = this.options[index];
      if (option.tmp > 0) {
        option.tmpText = option.items[option.tmp];
      } else {
        option.tmpText = '';
      }
      this.sync();
    },
    // her suboption eklendiğinde, arkaplanda biz karıştırararak options a kaydedilir.
    sync() {
      let out = [];
      let outStudent = [];
      for (let i = 0; i < this.options.length; i++) {
        const subOption = this.options[i];
        const subOptionsStudent = JSON.parse(
          JSON.stringify(this.options[i]),
        );
        out.push(subOption.items[0]); // orjinal cevaplar tek dizi olduğu için
        let tmpOut = subOptionsStudent.items;
        tmpOut = this.shuffle(tmpOut);
        outStudent.push(tmpOut); // orjinal şıkları çoklu dizi olduğu için stringe çevirilir.
      }
      this.model.optionsAnswer = out;
      this.model.options = outStudent;
    },
  },
  watch: {
    options() {
      this.sync();
    },
  },
};
</script>

<style>
textarea#text-src {
  border-color: #1c295c;
}
.selected-blank .option-delete {
  position: absolute;
  left: -2rem;
  top: 0;
  color: #1c2a5c;
  width: 75px;
}
</style>

<style scoped>
.reorder-input {
  position: relative;
}

.reorder-add {
  position: absolute;
  left: 50px;
  top: 7rem;
  margin-left: 0 !important;
  width: 120px;
}
.single-drag {
  position: relative;
  border: 1px solid #efefef;
  padding: 1rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  cursor: move;
  margin-bottom: 1rem;
}

.single-drag > span.drag-number {
  position: absolute;
  left: -3rem;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
}

.option-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  font-size: 0.9rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0 !important;
  background: white;
  background-image: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 0.5em;
  color: #1c295c;
  font-weight: 500;
  cursor: pointer;
}
.select-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 10em;
  height: 2.4em;
  line-height: 2.4;
  background: #f0f2f5;
  overflow: hidden;
  border-radius: 0.25em;
  border: 1px solid #1c295c;
  margin-right: 1rem;
  float: right;
}

.select-wrap::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  color: #1c295c;
  right: 0;
  padding: 0 1em;
  background: transparent;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
}

.sub-option-btns {
  position: absolute;
  right: -140px;
  top: 0;
}

.select-wrap:hover::after {
  color: #f39c12;
}
.sub-option-action > input {
  font-size: 0.9rem;
}
.selected-blank {
  margin-top: 1.5rem;
}
</style>
