<template>
  <div>
    <el-row>
      <el-col class="reorder-editor" :md="{ span: 21, offset: 0 }" :sm="24">
        <el-button
          :disabled="!showAdd"
          class="reorder-add"
          icon="el-icon-fa-plus"
          type="primary"
          @click="addOption"
        >
          <app-i18n code="common.add"></app-i18n>
        </el-button>
        <el-form-item>
          <div id="editor-wrap">
            <el-input
              type="textarea"
              :autosize="{ minRows: 10 }"
              v-model="model.editor"
              id="text-src"
              :class="checkRTL"
            />
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 18, offset: 3 }" :sm="24">
        <span class="re-order-exp-header">Answer Key</span>
        <small class="re-order-exp">
          Please put paragraphs in correct order. System will randomly shuffle
          options in the exam.
          </small>
        <hr />
        <div class="order-items">
          <Container @drop="onDrop">
            <Draggable v-for="(_, index) in model.optionsAnswer" :key="index">
              <!-- <div class="single-drag" :class="checkRTL"> -->
              <div
                class="single-drag"
                :class="{ 'single-drag-editing': isEditingIndex === index }"
              >
                <span class="drag-number">{{ index + 1 }}</span>
                <span v-if="isEditingIndex != index">{{
                  model.optionsAnswer[index]
                }}</span>
                <textarea
                  cols="30"
                  style="width: 100%"
                  rows="4"
                  @blur="editInputLeave(index)"
                  v-model="model.optionsAnswer[index]"
                  v-if="isEditingIndex == index"
                  class="edit-text"
                >
                </textarea>
                <span @click="editExtra(index)" class="option-edit"
                  ><i
                    :class="{
                      'el-icon-edit': isEditingIndex != index,
                      'el-icon-fa-floppy-o': isEditingIndex == index
                    }"
                  ></i
                ></span>
                <span
                  @click="removeExtra(index)"
                  class="option-delete"
                  :class="{ 'option-delete-disabled': isEditingIndex == index }"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </Draggable>
          </Container>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';
import { Container, Draggable } from 'vue-smooth-dnd';

let selectionStart;
let selectionStop;
let selectedText;

export default {
  name: 'reorderParagraph',
  props: {
    fields: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    Draggable,
    Container
  },
  data() {
    return {
      step: 0,
      showAdd: false,
      isEditing: false,
      isEditingIndex: null,
      isEditingAnswer: ''
    };
  },
  created() {
    document.addEventListener('selectionchange', this.handleSelection);
    this.resetSelection();
  },
  mounted() {
    document.getElementById('editor-wrap').onmouseup = this.mainHandle;
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL'
    })
  },

  methods: {
    editInputLeave(index) {
      this.editExtra(index);
    },
    removeExtra(index) {
      if (this.isEditingIndex != index)
        this.model.optionsAnswer.splice(index, 1);
    },
    editExtra(index) {
      if (this.isEditingIndex === index) {
        this.isEditingIndex = null;
      } else {
        this.isEditingIndex = index;
      }
    },
    onDrop(dropResult) {
      this.model.optionsAnswer = this.applyDrag(
        this.model.optionsAnswer,
        dropResult
      );
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = null;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    resetSelection() {
      selectionStart = 0;
      selectionStop = 0;
      selectedText = '';
      this.showAdd = false;
    },
    mainHandle() {
      if (this.model.editor == null) return; // substring error
      selectedText = this.model.editor
        .substring(selectionStart, selectionStop)
        .trim();
      if (selectedText === '') {
        this.resetSelection();
        return;
      }
      if (!this.showAdd) {
        this.showAdd = true;
      }
    },
    addOption() {
      selectedText = UtilsText.HandleEncoder(selectedText);
      this.model.optionsAnswer.push(selectedText);
      this.model.editor = this.model.editor.replace(selectedText, '');
      this.resetSelection();
    },
    handleSelection() {
      const activeElement = document.activeElement;
      if (activeElement.id !== 'text-src') {
        this.resetSelection();
        return;
      }
      selectionStart = activeElement.selectionStart;
      selectionStop = activeElement.selectionEnd;
    }
  }
};
</script>

<style>
textarea#text-src {
  border-color: #1c295c;
}
.reorder-editor label {
  font-weight: bold !important;
}
</style>

<style scoped>
.reorder-input {
  position: relative;
}
.q-editor {
  position: absolute;
  left: 50px;
  top: 3rem;
  width: 120px;
}
.reorder-editor {
  position: relative;
  min-height: 200px;
}
.reorder-add {
  position: absolute;
  left: 50px;
  top: 7rem;
  margin-left: 0 !important;
  width: 120px;
}
.single-drag {
  position: relative;
  border: 1px solid #efefef;
  padding: 1rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 60px;
  margin-right: 80px;
}
.single-drag-editing {
  padding: 0px !important;
}
.single-drag > span.drag-number {
  position: absolute;
  left: -2.5rem;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
}
span.option-delete {
  position: absolute;
  right: -2.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

span.option-delete:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}
span.option-edit {
  position: absolute;
  right: -2.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
span.option-edit:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}
.single-drag > span.option-delete {
  top: calc(50% - 15px);
  right: -5.0rem;
}
.single-drag > span.option-edit {
  top: calc(50% - 15px);
}
.option-delete-disabled {
  opacity: 0.5;
}
.option-delete-disabled:hover {
  cursor: no-drop !important;
  background-color: transparent !important;
}
.edit-text {
  font-family: 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', 'SimSun', 'sans-serif';
}
.re-order-exp-header{
  display: flex;
  justify-content: center;
}
.re-order-exp {
  font-style: italic;
  margin: 5px 1rem 1rem 0;
  display: flex;
  justify-content: center;
}
</style>
