<template>
  <div class="edit-form">
    <div class="edit-fixed-buttons">
      <el-button
        :disabled="saveLoading"
        @click="doReset"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>

      <el-button
        :disabled="saveLoading"
        @click="doCancel"
        icon="el-icon-fa-close"
      >
        <app-i18n code="common.cancel"></app-i18n>
      </el-button>
    </div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <div class="q-form-title">
        <app-i18n :code="`entities.onlineExam.types.${type}.type`" />
      </div>
      <div>
        <el-steps class="edit-steps" :active="activeStep">
          <el-step title="Info" icon="el-icon-info"></el-step>
          <el-step title="Data" icon="el-icon-edit"></el-step>
        </el-steps>
        <div v-if="activeStep === 0" class="edit-steps-content">
          <el-row>
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.keywords.label"
                :prop="fields.keywords.name"
                :required="fields.keywords.required"
              >
                <el-input v-model="model[fields.keywords.name]" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="!isForMExam">
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.questionLanguage.label"
                :prop="fields.questionLanguage.name"
                :required="fields.questionLanguage.required"
              >
                <el-col :lg="11" :md="16" :sm="24">
                  <app-language-autocomplete-input
                    :fetchFn="fields.questionLanguage.fetchFn"
                    :mapperFn="fields.questionLanguage.mapperFn"
                    v-model="model[fields.questionLanguage.name]"
                    mode="single"
                  ></app-language-autocomplete-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="isForMExam">
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.description.label"
                :prop="fields.description.name"
                :required="fields.description.required"
              >
                <el-input v-model="model[fields.description.name]" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="!isForMExam">
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.questionGroups.label"
                :prop="fields.questionGroups.name"
                :required="fields.questionGroups.required"
              >
                <el-col :lg="11" :md="16" :sm="24">
                  <app-question-group-autocomplete-input
                    :fetchFn="fields.questionGroups.fetchFn"
                    :mapperFn="fields.questionGroups.mapperFn"
                    v-model="model[fields.questionGroups.name]"
                    mode="multiple"
                  ></app-question-group-autocomplete-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.lockUntil.label"
                :prop="fields.lockUntil.name"
                :required="fields.lockUntil.required"
              >
                <el-col :lg="11" :md="16" :sm="24">
                  <el-date-picker
                    placeholder
                    type="datetime"
                    v-model="model[fields.lockUntil.name]"
                  ></el-date-picker>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="edit-nav-single edit-nav">
            <el-button
              icon="el-icon-fa-chevron-right"
              type="primary"
              @click="next"
            >
              <app-i18n code="common.next"></app-i18n>
            </el-button>
          </div>
        </div>
        <div v-if="activeStep === 1" class="edit-steps-content">
          <component
            :fields="fields"
            :record="recordData"
            :model="model"
            :key="type"
            v-bind:is="type"
          />
          <el-row class="status-wrap">
            <el-col :md="{ span: 12, offset: 5 }" :sm="24">
              <el-form-item
                :label="fields.questionStatus.label"
                :prop="fields.questionStatus.name"
                :required="fields.questionStatus.required"
              >
                <el-col :lg="11" :md="16" :sm="24">
                  <el-select v-model="model[fields.questionStatus.name]">
                    <el-option
                      :key="option.id"
                      :label="option.label"
                      :value="option.id"
                      v-for="option in questionStatusList"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="multi edit-nav">
            <el-form-item>
              <div class="form-buttons">
                <el-button
                  icon="el-icon-fa-chevron-left"
                  type="primary"
                  @click="prev"
                >
                  <app-i18n code="common.prev"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSaveAs"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                >
                  <app-i18n v-if="!showUpdateButton" code="common.save"></app-i18n>
                  <app-i18n v-if="showUpdateButton" code="common.saveAs"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
            <el-form-item v-if="showUpdateButton">
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doUpdate"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                >
                  <app-i18n code="common.update"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { QuestionModel } from '@/modules/question/question-model';
import Message from '@/shared/message/message';
import UtilsText from '@/shared/utils-text'; // frontend/src/shared/utils-text.js
import ReadAloud from './types/readAloud';
import RepeatSentence from './types/repeatSentence';
import DescribeImage from './types/describeImage';
import RetellLecture from './types/retellLecture';
import AnswerShortQuestion from './types/answerShortQuestion';
import SummarizeWrittenText from './types/summarizeWrittenText';
import WriteEssay from './types/writeEssay';
import SingleOption from './types/singleOption';
import MultipleOption from './types/multipleOption';
import ReorderParagraph from './types/reorderParagraph';
import FillInTheBlanks from './types/fillInTheBlanks';
import SelectedFillInTheBlanks from './types/selectedFillInTheBlanks';
import HighlightCorrectSummary from './types/highlightCorrectSummary';
import SummarizeSpokenText from './types/summarizeSpokenText';
import MultipleAnswer from './types/multipleAnswer';
import SingleAnswer from './types/singleAnswer';
import SelectMissingWord from './types/selectMissingWord';
import WriteFromTheDictation from './types/writeFromTheDictation';
import WriteInTheBlanks from './types/writeInTheBlanks';
import HighlightIncorrectWords from './types/highlightIncorrectWords';
import AppI18n from '../../../shared/i18n/i18n';
import { cloneDeep } from 'lodash';

const { fields } = QuestionModel;
const fieldsForTypes = {
  readAloud: [
    fields.question,
    fields.initialTime,
    fields.readingTime
  ],
  repeatSentence: [
    fields.initialTime,
    fields.recordTime,
    fields.audio,
    fields.answerHint
  ],
  describeImage: [
    fields.initialTime,
    fields.recordTime,
    fields.image
  ],
  retellLecture: [
    fields.initialTime,
    fields.recordTime,
    fields.audio,
    fields.image
  ],
  answerShortQuestion: [
    fields.initialTime,
    fields.recordTime,
    fields.audio,
    fields.answerHint
  ],
  summarizeWrittenText: [
    fields.time,
    fields.question
  ],
  writeEssay:[
    fields.time,
    fields.question
  ],
  singleOption:[
    fields.question,
    fields.subtitle,
    fields.options,
    fields.input
  ],
  multipleOption: [
    fields.question,
    fields.subtitle,
    fields.options,
    fields.inputs
  ],
  reorderParagraph:[
    fields.editor,
    fields.options,
    fields.optionsAnswer
  ],
  fillInTheBlanks:[
    fields.question,
    fields.options,
    fields.optionsAnswer,
    fields.extraOptions
  ],
  selectedFillInTheBlanks: [
    fields.question,
    fields.options,
    fields.optionsAnswer,
  ],
  highlightCorrectSummary:[
    fields.options,
    fields.audio,
    fields.input
  ],
  summarizeSpokenText:[
    fields.time,
    fields.audio,
  ],
  multipleAnswer:[
    fields.question,
    fields.audio,
    fields.options,
    fields.inputs
  ],
  writeInTheBlanks: [
    fields.audio,
    fields.question,
    fields.optionsAnswer
  ],
  singleAnswer:[
    fields.question,
    fields.audio,
    fields.options,
    fields.input
  ],
  selectMissingWord:[
    fields.audio,
    fields.options,
    fields.input
  ],
  highlightIncorrectWords: [
    fields.audio,
    fields.question,
    fields.optionsAnswer,
    fields.editor
  ],
  writeFromTheDictation: [
    fields.initialTime,
    fields.audio,
    fields.transcribe
  ]
}
const submitForFields = {
  readAloud: function ({ values }) {
    values.data = {
      question: values.question,
      initialTime: values.initialTime,
      readingTime: values.readingTime
    };
    delete values.question;
    delete values.initialTime;
    delete values.readingTime;
  },
  repeatSentence: function ({ values, record }) {
    values.data = {
      initialTime: values.initialTime,
      recordTime: values.recordTime,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      answerHint: values.answerHint
    };
    delete values.initialTime;
    delete values.recordTime;
    delete values.audio;
  },
  describeImage: function ({ values, record }) {
    values.data = {
      initialTime: values.initialTime,
      recordTime: values.recordTime,
      src:
        values.image && values.image.length > 0
          ? values.image[0].publicUrl
          : record.data.src
    };
    delete values.image;
    delete values.initialTime;
    delete values.recordTime;
    //delete values.image;
  },
  retellLecture: function ({ values, record }) {
    values.data = {
      initialTime: values.initialTime,
      recordTime: values.recordTime,
      src: (values.audio && values.audio.length > 0) ? values.audio[0].publicUrl : record.data.src,
    }
    if(values.image && values.image.length > 0){
      //Image zorunlu degil o yuzden varsa olussun.
      values.data.srcImg = values.image[0].publicUrl
    }
    delete values.initialTime;
    delete values.recordTime;
    delete values.audio;
    delete values.image;
  },
  answerShortQuestion: function ({ values, record }) {
    values.data = {
      initialTime: values.initialTime,
      recordTime: values.recordTime,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      answerHint: values.answerHint
    };

    delete values.initialTime;
    delete values.recordTime;
    delete values.audio;
  },
  summarizeWrittenText: function ({ values }) {
    values.data = {
      question: values.question,
      time: values.time * 60
    };
    delete values.question;
    delete values.time;
  },
  writeEssay: function ({ values }) {
    values.data = {
      question: values.question,
      time: values.time * 60
    };
    delete values.question;
    delete values.time;
  },
  singleOption: function ({ values }) {
    values.data = {
      question: values.question,
      options: values.options,
      subtitle: values.subtitle
    };
    values.answer = {
      input: values.input
    };
    delete values.question;
    delete values.options;
    delete values.subtitle;
    delete values.input;
  },
  multipleOption: function ({ values }) {
    values.data = {
      question: values.question,
      options: values.options,
      subtitle: values.subtitle
    };
    // Soruda seçeneklerinde olmayan answerların temizlenmesi.
    values.inputs = values.inputs.filter(i => values.options.includes(i));
    values.answer = {
      inputs: values.inputs
    };
    delete values.question;
    delete values.options;
    delete values.subtitle;
    delete values.inputs;
  },
  reorderParagraph: function ({ values }) {
    values.data = {
      options: shuffle(cloneDeep(values.optionsAnswer))
    };
    values.answer = {
      editor: values.editor,
      optionsAnswer: values.optionsAnswer
    };
    delete values.options;
    delete values.editor;
    delete values.optionsAnswer;
  },
  fillInTheBlanks: function ({ values }) {
    values.data = {
      question: values.question,
      options: values.options
    };
    values.answer = {
      extraOptions: values.extraOptions,
      optionsAnswer: values.optionsAnswer
    };
    delete values.question;
    delete values.options;
    delete values.extraOptions;
    delete values.optionsAnswer;
  },
  selectedFillInTheBlanks: function ({ values }) {
    values.data = {
      question: values.question,
      options: values.options
    };
    values.answer = {
      optionsAnswer: values.optionsAnswer
    };
    delete values.question;
    delete values.options;
    delete values.optionsAnswer;
  },
  highlightCorrectSummary: function ({ values, record }) {
    values.data = {
      options: values.options,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      input: values.input
    };
    delete values.options;
    delete values.audio;
    delete values.input;
  },
  summarizeSpokenText: function ({ values, record }) {
    values.data = {
      time: values.time * 60,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    delete values.time;
    delete values.audio;
  },
  multipleAnswer: function ({ values, record }) {
    values.data = {
      question: values.question,
      options: values.options,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    // Soruda seçeneklerinde olmayan answerların temizlenmesi.
    values.inputs = values.inputs.filter(i => values.options.includes(i));
    values.answer = {
      inputs: values.inputs
    };
    delete values.question;
    delete values.options;
    delete values.audio;
    delete values.inputs;
  },
  writeInTheBlanks: function ({ values, model, record }) {
    values.data = {
      question: values.question,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      optionsAnswer: UtilsText.OptionsLowerCase(
        values.optionsAnswer,
        model.questionLanguage.code
      )
    };
    delete values.question;
    delete values.audio;
    delete values.optionsAnswer;
  },
  singleAnswer: function ({ values, record }) {
    values.data = {
      question: values.question,
      options: values.options,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      input: values.input
    };
    delete values.question;
    delete values.options;
    delete values.audio;
    delete values.input;
  },
  selectMissingWord: function ({ values, record }) {
    values.data = {
      options: values.options,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      input: values.input
    };
    delete values.options;
    delete values.audio;
    delete values.input;
  },
  highlightIncorrectWords: function ({ values, model, record }) {
    values.data = {
      question: values.question,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      optionsAnswer: UtilsText.OptionsLowerCase(
        values.optionsAnswer,
        model.questionLanguage.code
      ),
      editor: values.editor
    };
    delete values.question;
    delete values.editor;
    delete values.audio;
    delete values.optionsAnswer;
  },
  writeFromTheDictation: function ({ values, model, record }) {
    values.data = {
      initialTime: values.initialTime,
      src:
        values.audio && values.audio.length > 0
          ? values.audio[0].publicUrl
          : record.data.src
    };
    values.answer = {
      transcribe: UtilsText.LowerCase(
        values.transcribe,
        model.questionLanguage.code
      )
    };
    delete values.transcribe;
    delete values.initialTime;
    delete values.audio;
  }
};
let formSchema = null;

const initialTimeMap = {
  'Describe Image': 25,
  'Re-tell Lecture': 10,
  'Repeat Sentence': 5,
  'Highlight Incorrect Words': 6,
  'Answer Short Question': 2,
  'Write From The Dictation': 6,
  'Write Essay': 20
};
const recordTimeMap = {
  'Re-tell Lecture': 40,
  'Repeat Sentence': 8,
  'Answer Short Question': 7
};

function getInitialTime(questionType) {
  if (questionType in initialTimeMap) {
    return initialTimeMap[questionType];
  }
  return 20;
}
function getRecordTime(questionType) {
  if (questionType in recordTimeMap) {
    return recordTimeMap[questionType];
  }
  return 40;
}

function shuffle(array) {
  let counter = array.length;
  if (counter === 1) return;
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter);
    // Decrease counter by 1
    counter--;
    // And swap the last element with it
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

export default {
  name: 'QuestionEditForm',
  components: {
    AppI18n,
    ReadAloud,
    RepeatSentence,
    DescribeImage,
    RetellLecture,
    AnswerShortQuestion,
    SummarizeWrittenText,
    WriteEssay,
    SingleOption,
    MultipleOption,
    ReorderParagraph,
    FillInTheBlanks,
    SelectedFillInTheBlanks,
    HighlightCorrectSummary,
    SummarizeSpokenText,
    MultipleAnswer,
    SingleAnswer,
    SelectMissingWord,
    WriteInTheBlanks,
    HighlightIncorrectWords,
    WriteFromTheDictation
  },
  props: ['record', 'saveLoading', "isForMExam"],

  data() {
    return {
      rules: null,
      model: null,
      activeStep: 1,
      recordData: this.record || {}
    };
  },

  created() {
    formSchema = new FormSchema([
      fields.id,
      fields.keywords,
      fields.questionLanguage,
      fields.description,
      fields.questionGroups,
      fields.lockUntil,
      fields.questionStatus,
      ...fieldsForTypes[this.type] //her tip icin gerekli alanlari diziden ceker
    ]);
    this.rules = formSchema.rules();
    if (this.record) {
      if (!this.record.data || Object.keys(this.record.data)[0] === 'description') {
        const questionType = this.record.questionType.questionType;
        //Navigated to Edit from Create
        this.recordData.initialTime = getInitialTime(questionType);
        this.recordData.recordTime = getRecordTime(questionType);
        //this.record.initialTime = 20;
        this.recordData.readingTime = 40;
        this.recordData.selections = [];
        this.recordData.inputs = [];
        if (this.record.data)
          this.recordData.description = this.record.data.description ? this.record.data.description : '';
      } else {
        //Show Edit Data
        if (this.record.data) {
          //console.log("Data ici dagitiliyor..");
          this.recordData = { ...this.recordData, ...this.record.data };
          delete this.recordData.data;
        }
        if (this.recordData.answer) {
          //console.log("Answer ici dagitiliyor..");
          this.recordData = { ...this.recordData, ...this.record.answer };
          delete this.recordData.answer;
        }
      }
    }
    this.model = formSchema.initialValues(this.recordData || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      hasAdminRole: 'auth/hasAdminRole',
      type: 'question/form/questionType'
    }),
    fields() {
      return fields;
    },
    showUpdateButton() {
      return this.recordData.questionStatus !== '1';
    },
    questionStatusList() {
      if (this.recordData.questionStatus === '9') {
        return this.fields.questionStatus.options;
      } else {
        return this.fields.questionStatus.options.filter(
          (o) => o.label !== 'Obsolete'
        );
      }
    }
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
      this.model.options = [];
      this.model.optionsAnswer = [];
      this.model.question = '';
      this.model.editor = '';
      this.model.extraOptions = [];
    },

    next() {
      this.activeStep++;
    },

    prev() {
      this.activeStep--;
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doUpdate() {
      this.doSubmit(true);
    },
    async doSaveAs() {
      this.doSubmit(false);
    },
    async doSubmit(isForce = false) {
      if (this.model.optionsAnswer && this.model.optionsAnswer.length === 0) {
        Message.error('Please select options');
        return;
      }
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      //console.log("MODEL: ", this.model);
      const { id, ...values } = formSchema.cast(this.model);
      //console.log("Values: ", values);
      if (submitForFields[this.type]) {
        //console.log("FIELDS den alinir mi: ", fieldsForTypes);
        submitForFields[this.type]({
          values,
          model: this.model,
          record: this.record
        });
      }

      if (values.description) {
        values.data.description = values.description;
        delete values.description;
      }

      if (this.isForMExam && values.answer) {
        values.answer = {...values.answer, questionPoint: this.record.answer.questionPoint}
      }

      return this.$emit('submit', {
        id,
        values,
        isForce: isForce
      });
    }
  }
};
</script>

<style scoped>
.status-wrap {
  margin-top: 2rem;
}
.q-form-title {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #262626;
  font-size: 1.3rem;
}
</style>
