<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.time.label"
          :prop="fields.time.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.time.name]"
            ref="focus"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.audio.label"
          :prop="fields.audio.name"
          :required="!hasSrc()"
        >
          <app-file-upload
            :path="fields.audio.path"
            :schema="fields.audio.fileSchema"
            v-model="model[fields.audio.name]"
            :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="checkAudio()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="model.audio[0].publicUrl" controls />
      </el-col>
    </el-row>
    <el-row v-if="!checkAudio() && hasSrc()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="record.src" controls />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'summarizeSpokenText',
  props: ['fields','model','record'],
  beforeMount() {
    //Db de saniye olarak kaydediliyor gosterimde dakikaya ceviriyorum.
    if(this.model[this.fields.time.name]){
      this.model[this.fields.time.name] = this.model[this.fields.time.name] / 60;
    }else{
      this.model[this.fields.time.name] = 10;
    }
  },
  methods: {
    hasSrc() {
      //console.log("hassrc",this.record && !!this.record.src);
      return this.record && !!this.record.src;
    },
    checkAudio() {
      const newAudio = (
              this.model.audio &&
              this.model.audio.length > 0
      );
      if(newAudio === true && this.record){
        this.record.src = "";
      }
      //console.log("newAudio",newAudio);
      return newAudio;
    },
  },
};
</script>

<style scoped></style>
