<template>
  <div>
    <el-row v-show="step === 0">
      <el-col
        class="reorder-input"
        :md="{ span: 24, offset: 0 }"
        :sm="24"
      >
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          required
          :class="checkRTL"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 12 }"
            v-model="model[fields.question.name]"
            id="editor-wrap"
            @blur="handleBlur"
          />
        </el-form-item>
      </el-col>
      <el-button
        v-show="showAdd"
        class="reorder-add"
        icon="el-icon-fa-plus"
        type="primary"
        @click="addOption"
      >
        <app-i18n code="common.add"></app-i18n>
      </el-button>
    </el-row>

    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="order-items">
          <div
            class="single-drag"
            v-for="(option, index) in model.optionsAnswer"
            :key="index"
            :class="checkRTL"
          >
            <span class="drag-number">{{ index + 1 }}</span>
            {{ printOption(option) }}
            <span
              @click="removeOption(index)"
              class="option-delete"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="extra-items">
          <div
            class="extra-item"
            :key="index"
            v-for="(_, index) in model.extraOptions"
          >
            <el-form-item
              :prop="'options.' + index"
              :rules="{
                required: true,
                message: 'Required',
                trigger: 'blur',
              }"
              :class="checkRTL"
            >
              <el-input
                placeholder="Extra Option"
                v-model="model.extraOptions[index]"
                type="textarea"
                :autosize="{ minRows: 2 }"
                @blur="sync"
              />
            </el-form-item>
            <span
              @click="removeExtra(index)"
              class="option-delete"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
      v-if="
        model.optionsAnswer &&
          model.optionsAnswer.length > 0
      "
    >
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="option-action">
          <el-button
            @click="addExtraOption"
            icon="el-icon-fa-plus"
            type="primary"
          >
            Add Extra Option
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';

let selectionStart;
let selectionStop;
let selectedText;
export default {
  name: 'fillInTheBlanks',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      showAdd: false,
    };
  },
  created() {
    document.addEventListener(
      'selectionchange',
      this.handleSelection,
    );
    this.resetSelection();
  },
  mounted() {
    //console.log(document.getElementById('editor-wrap'));
    document.getElementById(
      'editor-wrap',
    ).onmouseup = this.mainHandle;
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
    }),
  },
  methods: {
    handleBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
    removeOption(index) {
      const parts = this.model.question.split('$$');
      let newStr = '';
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        newStr += part;
        if (i === index) {
          newStr += this.model.optionsAnswer[index];
        } else if (i < parts.length - 1) {
          newStr += '$$';
        }
      }
      this.$delete(this.model.optionsAnswer, index);
      this.model.question = newStr;
      this.sync();
    },
    resetSelection() {
      selectionStart = 0;
      selectionStop = 0;
      selectedText = '';
      this.showAdd = false;
    },
    addExtraOption() {
      this.model.extraOptions.push('');
    },
    removeExtra(index) {
      this.$delete(this.model.extraOptions, index);
    },
    printOption(option) {
      try {
        option = JSON.parse(option);
        if ('text' in option) {
          return option['text'];
        }
      } catch (e) {
        return option;
      }
      return option;
    },
    getOptionIndex() {
      const before = this.model.question.substr(
        0,
        selectionStart,
      );
      return before.split('$$').length - 1;
    },
    mainHandle() {
      //console.log('main handle');
      if (this.model.question == null) return;
      selectedText = this.model.question.substring(
        selectionStart,
        selectionStop,
      );
      if (selectedText.trim() === '') {
        this.resetSelection();
        return;
      }
      if (!this.showAdd) {
        this.showAdd = true;
      }
    },
    shuffle(array) { // Re-Orderdaki gibi verimli hale getirilebilir
      let counter = array.length;
      if (counter < 2) return array;

      // While there are elements in the array
      while (counter > 0) {
        // Pick a random index
        let index = Math.floor(Math.random() * counter);

        // Decrease counter by 1
        counter--;

        // And swap the last element with it
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
      }

      return array;
    },
    // yeni senaryoda seçilen textlerin yerine $$ gelecek
    // seçilen metinler optionsAnswer a eklenecek.
    // seçilen metinlerin altına elle ekstra seçenek ekleyebilecek.
    // bunlar karıştırılarak options olarak kaydedilecek.
    // öğretmen mecburen sıralı şekilde yapacak.
    addOption() {
      // kendinden onceki elemandan sonra insert ediyoruz
      this.model.optionsAnswer.splice(
        this.getOptionIndex(),
        0,
        selectedText,
      );
      //options'i karistirarak hazirla todo bunu save esnasinda yap
      this.sync();
      this.model.question = this.replace(
        this.model.question,
        selectionStart,
        selectionStop - 1,
      );
      this.resetSelection(); //Selection degerlerini sifirla
    },
    placeholder(start, end) {
      let out = '';
      for (let i = 0; i < end - start; i++) {
        out += '_';
      }
      return out + ' ';
    },
    handleSelection() {
      const activeElement = document.activeElement;
      if (activeElement.id !== 'editor-wrap') {
        this.resetSelection();
        return;
      }
      selectionStart = activeElement.selectionStart;
      selectionStop = activeElement.selectionEnd;
    },
    next() {
      this.step++;
    },
    prev() {
      this.step--;
    },
    replace(origin, start, end) {
      const firstPart = origin.substr(0, start);
      const middlePart = '$$';
      const lastPart = origin.substr(end + 1);
      return firstPart + middlePart + lastPart;
    },
    sync() {
      this.model.options = this.shuffle(
        this.model.optionsAnswer.concat(
          this.model.extraOptions,
        ),
      );
    },
  },
};
</script>

<style>
textarea#text-src {
  border-color: #1c295c;
}

.extra-item {
  position: relative;
}

.extra-item span.option-delete {
  top: 0.5rem;
}

.order-items .option-delete {
  top: 0.5rem;
}

.extra-item .el-form-item__content {
  margin-left: 0 !important;
}
</style>

<style scoped>
.reorder-input {
  position: relative;
}

.extra-items .el-form-item__content {
  margin-left: 0 !important;
}

.reorder-add {
  position: absolute;
  left: 50px;
  top: 7rem;
  margin-left: 0 !important;
  width: 120px;
}
.single-drag {
  position: relative;
  border: 1px solid #efefef;
  padding: 1rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.single-drag > span.drag-number {
  position: absolute;
  left: -3rem;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
}
</style>
