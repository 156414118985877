<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.audio.label"
          :prop="fields.audio.name"
          :required="!hasSrc()"
        >
          <app-file-upload
            :path="fields.audio.path"
            :schema="fields.audio.fileSchema"
            v-model="model[fields.audio.name]"
            :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="checkAudio()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="model.audio[0].publicUrl" controls />
      </el-col>
    </el-row>
    <el-row v-if="!checkAudio() && hasSrc()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="record.src" controls />
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="options-wrap">
          <div class="options-pick">
            <el-form-item
              required
              prop="input"
              v-if="model.options.length > 0"
              :class="rtlAndPadding"
            >
              <el-radio-group v-model="model.input">
                <el-radio
                  :key="index"
                  v-for="(option, index) in model.options"
                  :label="option"
                  > <span class="card-title"> {{ index + 1 }} </span> </el-radio
                >
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="options-items">
            <div
              class="options-item"
              :key="index"
              v-for="(_, index) in model.options"
            >
              <el-form-item
                :prop="'options.' + index"
                :rules="{
                  required: true,
                  message: 'Required',
                  trigger: 'blur',
                }"
                :class="checkRTL"
              >
                <el-input
                  placeholder="Option"
                  v-model="model.options[index]"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                  @blur="handleOptionBlur($event, index)"
                />
              </el-form-item>
              <span
                @click="remove(index)"
                class="option-delete"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="option-action">
          <el-button
            @click="addOption"
            icon="el-icon-fa-plus"
            type="primary"
          >
            Add Option
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';
export default {
  name: 'highlightCorrectSummary',
  props: ['fields','model','record'],
  
  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
      rtlAndPadding: 'question/form/rtlAndPadding',
    }),
  },

  methods: {
    handleOptionBlur(e, index) {
      this.model.options[index] = UtilsText.HandleEncoder(
        e.target.value,
      );
      e.target.value = this.model.options[index];
    },
    addOption() {
      this.model.options.push('');
    },
    remove(index) {
      const str = this.model.options[index].trim();
      if (str !== '') {
        const confirm = window.confirm('Are you sure');
        if (!confirm) return;
      }
      this.$delete(this.model.options, index);
      this.model.input = undefined;
    },
    hasSrc() {
      //console.log("Has src:", this.record && !!this.record.src);
      return this.record && !!this.record.src;
    },

    checkAudio() {
      const newAudio = (
              this.model.audio &&
              this.model.audio.length > 0
      );
      if(newAudio === true && this.record){
        this.record.src = "";
      }
      return newAudio;
    },
  },
};
</script>

<style scoped>
.option-action {
  display: flex;
  justify-content: center;
  border-top: 1px solid #efefef;
  padding-top: 2rem;
  margin-top: 2rem;
}
.options-wrap {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  border-top: 1px solid #efefef;
  margin-top: 1rem;
}

.options-pick {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-items {
  flex: 6;
}

span.option-delete {
  position: absolute;
  right: -2.5rem;
  top: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

span.option-delete:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}

.options-pick label {
  width: 100% !important;
  margin-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  height: 54px;
  align-items: center;
}
.options-pick .el-radio-group {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.options-pick > div > div {
  margin-left: 0 !important;
  height: 100%;
}

.options-pick > div {
  margin-bottom: 0;
  height: 100%;
}

.options-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
}
.options-item .el-form-item__content {
  margin-left: 0 !important;
}
.options-item > div {
  margin-bottom: 0;
}
.options-pick .el-form-item__error {
  left: -40px;
  width: 120px;
}
</style>
