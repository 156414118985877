<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.initialTime.label"
          :prop="fields.initialTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.initialTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.recordTime.label"
          :prop="fields.recordTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.recordTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.audio.label"
          :prop="fields.audio.name"
          :required="!hasSrc()"
        >
          <app-file-upload
            :path="fields.audio.path"
            :schema="fields.audio.fileSchema"
            v-model="model[fields.audio.name]"
            :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="checkAudio()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="model.audio[0].publicUrl" controls />
      </el-col>
    </el-row>
    <el-row v-if="!checkAudio() && hasSrc()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="record.src" controls />
      </el-col>
    </el-row>
    <el-row v-if="checkAudio() || hasSrc()" class="audio-answer">
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
                :label="fields.answerHint.label"
                :prop="fields.answerHint.name"
                :class="checkRTL"
                required
        >
          <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  v-model="model[fields.answerHint.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'answerShortQuestion',
  props: ['fields','model','record'],
  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
    })
  },
  methods: {
    hasSrc() {
      return this.record && !!this.record.src;
    },
    checkAudio() {
      const newAudio = (
              this.model.audio &&
              this.model.audio.length > 0
      );
      if(newAudio === true && this.record){
        this.record.src = "";
      }
      return newAudio;
    },
  },
};
</script>

<style scoped>
  .audio-answer {
    margin-top: 2rem;
  }
</style>
