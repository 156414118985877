<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.initialTime.label"
          :prop="fields.initialTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.initialTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.recordTime.label"
          :prop="fields.recordTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.recordTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.image.label"
          :prop="fields.image.name"
          :required="!hasSrc()"
        >
          <app-image-upload
            :path="fields.image.path"
            :schema="fields.image.fileSchema"
            v-model="model[fields.image.name]"
            :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'describeImage',
  props: ['fields','model','record'],
  beforeMount() {
    if(this.record && this.record.src){
      this.model[this.fields.image.name] = [{
        publicUrl: this.record.src
      }]
    }
  },
  methods: {
    hasSrc() {
      return this.record && !!this.record.src;
    },
  }
};
</script>

<style scoped/>

