<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.time.label"
          :prop="fields.time.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.time.name]"
            ref="focus"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          :class="checkRTL"
          required
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            v-model="model[fields.question.name]"
            @blur="handleBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex'
export default {
  name: 'writeEssay',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    //Db de saniye olarak kaydediliyor gosterimde dakikaya ceviriyorum.
    if(this.model[this.fields.time.name]){
      this.model[this.fields.time.name] = this.model[this.fields.time.name] / 60;
    }else{
      this.model[this.fields.time.name] = 20;
    }
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
    }),
  },

  methods: {
    handleBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
  },
};
</script>

<style scoped></style>
