<template>
  <div>
    <el-row>
      <el-col :md="{ span: 15, offset: 3 }" :sm="24">
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          required
          :class="checkRTL"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            v-model="model[fields.question.name]"
            @blur="handleBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.initialTime.label"
          :prop="fields.initialTime.name"
          :class="checkRTL"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.initialTime.name]"
            ref="focus"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.readingTime.label"
          :prop="fields.readingTime.name"
          :class="checkRTL"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.readingTime.name]"
            ref="focus"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';
export default {
  name: 'readAloud',
  props: {
    fields: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
    }),
  },

  methods: {
    handleBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
  },
};
</script>

<style scoped></style>
