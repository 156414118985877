var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}]},[_c('el-col',{staticClass:"reorder-input",attrs:{"md":{ span: 24, offset: 0 },"sm":24}},[_c('el-form-item',{class:_vm.checkRTL,attrs:{"label":_vm.fields.question.label,"prop":_vm.fields.question.name,"required":""}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 12 },"id":"editor-wrap"},on:{"blur":_vm.handleBlur},model:{value:(_vm.model[_vm.fields.question.name]),callback:function ($$v) {_vm.$set(_vm.model, _vm.fields.question.name, $$v)},expression:"model[fields.question.name]"}})],1)],1),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdd),expression:"showAdd"}],staticClass:"reorder-add",attrs:{"icon":"el-icon-fa-plus","type":"primary"},on:{"click":_vm.addOption}},[_c('app-i18n',{attrs:{"code":"common.add"}})],1)],1),_c('el-row',[_c('el-col',{attrs:{"md":{ span: 12, offset: 5 },"sm":24}},[_c('div',{staticClass:"order-items"},_vm._l((_vm.model.optionsAnswer),function(option,index){return _c('div',{key:index,staticClass:"single-drag",class:_vm.checkRTL},[_c('span',{staticClass:"drag-number"},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(_vm.printOption(option))+" "),_c('span',{staticClass:"option-delete",on:{"click":function($event){return _vm.removeOption(index)}}},[_c('i',{staticClass:"el-icon-delete"})])])}),0)])],1),_c('el-row',[_c('el-col',{attrs:{"md":{ span: 12, offset: 5 },"sm":24}},[_c('div',{staticClass:"extra-items"},_vm._l((_vm.model.extraOptions),function(_,index){return _c('div',{key:index,staticClass:"extra-item"},[_c('el-form-item',{class:_vm.checkRTL,attrs:{"prop":'options.' + index,"rules":{
              required: true,
              message: 'Required',
              trigger: 'blur',
            }}},[_c('el-input',{attrs:{"placeholder":"Extra Option","type":"textarea","autosize":{ minRows: 2 }},on:{"blur":_vm.sync},model:{value:(_vm.model.extraOptions[index]),callback:function ($$v) {_vm.$set(_vm.model.extraOptions, index, $$v)},expression:"model.extraOptions[index]"}})],1),_c('span',{staticClass:"option-delete",on:{"click":function($event){return _vm.removeExtra(index)}}},[_c('i',{staticClass:"el-icon-delete"})])],1)}),0)])],1),(
      _vm.model.optionsAnswer &&
        _vm.model.optionsAnswer.length > 0
    )?_c('el-row',[_c('el-col',{attrs:{"md":{ span: 12, offset: 5 },"sm":24}},[_c('div',{staticClass:"option-action"},[_c('el-button',{attrs:{"icon":"el-icon-fa-plus","type":"primary"},on:{"click":_vm.addExtraOption}},[_vm._v(" Add Extra Option ")])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }