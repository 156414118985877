<template>
  <div>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.initialTime.label"
          :prop="fields.initialTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.initialTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.recordTime.label"
          :prop="fields.recordTime.name"
          required
        >
          <el-input-number
            :min="1"
            v-model="model[fields.recordTime.name]"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
          :label="fields.audio.label"
          :prop="fields.audio.name"
          :required="!hasSrc()"
        >
          <app-file-upload
            :path="fields.audio.path"
            :schema="fields.audio.fileSchema"
            v-model="model[fields.audio.name]"
            :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="checkAudio()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="model.audio[0].publicUrl" controls />
      </el-col>
    </el-row>
    <el-row v-if="!checkAudio() && hasSrc()">
      <el-col :md="{ span: 12, offset: 8 }" :sm="24">
        <audio :src="record.src" controls />
      </el-col>
    </el-row>

    <el-row class="marTop">
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <el-form-item
                :label="fields.image.label"
                :prop="fields.image.name"
                :required="false"
        >
          <app-image-upload
                  :path="fields.image.path"
                  :schema="fields.image.fileSchema"
                  v-model="model[fields.image.name]"
                  :max="1"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'retellLecture',
  props: ['fields','model','record'],
  methods: {
    hasSrc() {
      return this.record && !!this.record.src;
    },
    hasSrcImg() {
      return this.record && !!this.record.srcImg;
    },
    checkAudio() {
      const newAudio = (
              this.model.audio &&
              this.model.audio.length > 0
      );
      if(newAudio === true && this.record){
        this.record.src = "";
      }
      return newAudio;
    },
  },
  beforeMount() {
    if(this.record && this.record.srcImg){
      this.model[this.fields.image.name] = [{
        publicUrl: this.record.srcImg
      }]
    }
  },
};
</script>

<style scoped>
  .marTop{
    margin-top: 2rem;
  }
</style>
