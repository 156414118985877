<template>
  <div>
    <div class="audio-top">
      <el-row>
        <el-col :md="{ span: 12, offset: 5 }" :sm="24">
          <el-form-item
            :label="fields.audio.label"
            :prop="fields.audio.name"
            :required="!hasSrc()"
          >
            <app-file-upload
              :path="fields.audio.path"
              :schema="fields.audio.fileSchema"
              v-model="model[fields.audio.name]"
              :max="1"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="checkAudio()">
        <el-col :md="{ span: 12, offset: 0 }" :sm="24">
          <audio :src="model.audio[0].publicUrl" controls />
        </el-col>
      </el-row>
      <el-row v-if="!checkAudio() && hasSrc()">
        <el-col :md="{ span: 12, offset: 0 }" :sm="24">
          <audio :src="record.src" controls />
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col
        class="reorder-input"
        :md="{ span: 24, offset: 0 }"
        :sm="24"
      >
        <el-button
          v-show="showAdd"
          class="reorder-add"
          icon="el-icon-fa-plus"
          type="primary"
          @click="addOption"
        >
          <app-i18n code="common.add"></app-i18n>
        </el-button>
        <el-form-item
          :label="fields.question.label"
          :prop="fields.question.name"
          :class="checkRTL"
          required
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 12 }"
            v-model="model[fields.question.name]"
            id="editor-wrap"
            @blur="handleBlur"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="{ span: 12, offset: 5 }" :sm="24">
        <div class="order-items">
          <div
            class="single-drag"
            :key="index"
            :class="checkRTL"
            v-for="(option, index) in model.optionsAnswer"
          >
            <span class="drag-number">{{ index + 1 }}</span>
            {{ printOption(option) }}
            <span
              @click="removeOption(index)"
              class="option-delete"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UtilsText from '@/shared/utils-text';
import { mapGetters } from 'vuex';
let selectionStart;
let selectionStop;
let selectedText;

export default {
  name: 'writeInTheBlanks',
  props: ['fields','model','record'],
  data() {
    return {
      showAdd: false,
    };
  },
  created() {
    document.addEventListener(
      'selectionchange',
      this.handleSelection,
    );
    this.resetSelection();
  },
  mounted() {
    //console.log(document.getElementById('editor-wrap'));
    document.getElementById(
      'editor-wrap',
    ).onmouseup = this.mainHandle;
  },

  computed: {
    ...mapGetters({
      checkRTL: 'question/form/checkRTL',
    }),
  },

  methods: {
    handleBlur(e) {
      this.model[
        this.fields.question.name
      ] = UtilsText.HandleEncoder(e.target.value);
    },
    removeOption(index) {
      const parts = this.model.question.split('$$');
      let newStr = '';
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        newStr += part;
        if (i === index) {
          newStr += this.model.optionsAnswer[index];
        } else if (i < parts.length - 1) {
          newStr += '$$';
        }
      }
      this.$delete(this.model.optionsAnswer, index);
      this.model.question = newStr;
    },
    resetSelection() {
      selectionStart = 0;
      selectionStop = 0;
      selectedText = '';
      this.showAdd = false;
    },
    printOption(option) {
      try {
        option = JSON.parse(option);
        if ('text' in option) {
          return option['text'];
        }
      } catch (e) {
        return option;
      }
      return option;
    },
    mainHandle() {
      //console.log('main handle');
      if (this.model.question == null) return;
      selectedText = this.model.question.substring(
        selectionStart,
        selectionStop,
      );
      if (selectedText.trim() === '') {
        this.resetSelection();
        return;
      }
      if (!this.showAdd) {
        this.showAdd = true;
      }
    },
    getOptionIndex() {
      const before = this.model.question.substr(
        0,
        selectionStart,
      );
      return before.split('$$').length - 1;
    },
    addOption() {
      //this.model.optionsAnswer.push(selectedText);
      this.model.optionsAnswer.splice(
        this.getOptionIndex(),
        0,
        selectedText,
      );
      this.model.question = this.replace(
        this.model.question,
        selectionStart,
        selectionStop - 1,
      );
      this.resetSelection();
    },
    handleSelection() {
      const activeElement = document.activeElement;
      if (activeElement.id !== 'editor-wrap') {
        this.resetSelection();
        return;
      }
      selectionStart = activeElement.selectionStart;
      selectionStop = activeElement.selectionEnd;
    },

    replace(origin, start, end) {
      const firstPart = origin.substr(0, start);
      const middlePart = '$$';
      const lastPart = origin.substr(end + 1);
      return firstPart + middlePart + lastPart;
    },
    hasSrc() {
      return this.record && !!this.record.src;
    },
    checkAudio() {
      const newAudio = (
              this.model.audio &&
              this.model.audio.length > 0
      );
      if(newAudio === true && this.record){
        this.record.src = "";
      }
      return newAudio;
    },
  },
};
</script>

<style>
textarea#text-src {
  border-color: #1c295c;
}
</style>

<style scoped>
.audio-top {
  display: flex;
  padding: 1rem 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #efefef;
  /*border-top: 1px solid #efefef;*/
  justify-content: center;
  align-items: center;
}

.reorder-input {
  position: relative;
}

.reorder-add {
  position: absolute;
  left: 50px;
  top: 7rem;
  margin-left: 0 !important;
  width: 120px;
}
.single-drag {
  position: relative;
  border: 1px solid #efefef;
  padding: 1rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  cursor: move;
  margin-bottom: 1rem;
}

.single-drag > span.drag-number {
  position: absolute;
  left: -3rem;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
}
.simple-options {
  padding: 0.5rem;
  margin-left: 3rem;
}

.simple-options > div {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #1c2a5c;
  position: relative;
  max-width: 200px;
}

span.simple-number {
  position: absolute;
  left: -1rem;
}
</style>
